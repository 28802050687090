import i18n from "i18next";
import React from "react";

i18n.init({
	resources: {
		en: {
			translations: {
				login: "Login",
				login_here: "Login here",
				signup: "Signup",
				signup_here: "Signup here",
				email: "Email address",
				password: "Password",
				reset_here: "Reset here",
				reset_your_password: "Reset your password",
				forgot_password: "Forgot your password?",
				full_name: "Full name",
				already_have_account: "Already have account?",
				send_reset_link: "Send reset link",
				want_to_login: "Want to login?",
				remember_me: "Remember me",
				email_address_required: `Email is required.`,
				email_address_valid: `Please enter a valid email address.`,
				password_required: `Password is required`,
				login_success_redirecting: `Login successful! Redirecting...`,
				name_required: `Name is required.`,
				signup_success_check_email: `Signup successful! Please check your email to activate your account.`,
				password_reset_success_check_email: `Please check your email to change your password.`,
				invalid_token: `Invalid activation token. Please do not change the provided URL.`,
				account_activation_success_heading: `Account Activation Success!`,
				account_activation_success_message: `Your account is activated successfully! Please login and start using the application.`,
				could_not_detect_location: `Could not detect your location.`,
				provide_all_fields: `Please provide all required fields.`,

				ad_updated: `Ad information updated successfully!`,
				ad_created: `Ad created and published successfully!`,
				create_ad: `Create Ad`,
				update_ad: `Update Ad`,
				save_changes: `Save Changes`,
				save_and_publish: `Save & Publish Ad`,
				go_back: `Go back`,
				details: `Details`,
				ad_details: `Ad details`,
				category: `Category`,
				sub_category: `Sub Category`,
				ad_title: `Ad Title`,
				ad_title_hint: `Mention the key features of your item (e.g. brand, model, age, type)`,
				condition: `Condition`,
				description: `Description`,
				description_hint: `Include condition, features and reason for selling`,
				photo_gallery: `Photo gallery`,
				phone_number: `Phone number`,
				select_photos: `Select photos`,
				select_photos_hint: `You can upload upto 20 photos`,
				price: `Price`,
				price_hint: `Minimum price is set to 0.1`,
				show_phone_number_on_ad: `Show my phone number on this ad`,
				confirm_location: `Confirm your location`,
				confirm_location_hint: `Click on the button to get access to your current location. Allow browser to access your
                        location for better selling experience.`,
				access_location: `Access my location`,
				city: `City`,
				state: `State`,
				country: `Country`,
				sure_to_remove_favorite: `Are you sure you want to remove it from your favourites?`,
				already_favorite_click_to_remove: `Already in favourites. Click to remove it from your favourites`,
				save_favorite: `Click to save as favourite`,
				ad_report_success: `Ad is reported successfully! Admin will review your report and update the ad status accordingly. Thank you.`,
				report_ad: `Report ad`,
				select_type: `Select type`,
				select_category: `Select category`,
				select_sub_category: `Select sub category`,
				select_condition: `Select condition`,
				report_message: `Message to admin`,

				submit: `Submit`,
				close: `Close`,
				edit: `Edit`,
				delete: `Delete`,
				deactivate: `Deactivate`,
				activate: `Activate`,
				make_available: `Make available`,
				make_sold: `Make sold out`,
				search: `Search`,
				clear: `Clear`,

				cannot_chat_yourself: `You cannot chat yourself!`,
				cannot_offer_yourself: `You cannot send offer to yourself!`,

				copy_public_url_to_share: `Copy public URL to share`,
				seller_information: `Seller information`,
				view_seller_profile: `Click to view seller profile`,
				seller_shared_phone: `Seller has shared his phone number`,
				view_profile: `View profile`,
				chat_with_seller: `Chat with seller`,
				make_offer: `Make an offer`,
				posted_in: `Posted in`,
				report_this_ad: `Report this ad`,

				filter_conversations: `Filter conversations...`,
				type_message: `Type your message...`,
				send: `Send`,

				offers: `Offers`,
				messages: `Messages`,
				my_profile: `My profile`,
				my_public_profile: `My public profile`,
				my_ads: `My ads`,
				my_favorite_ads: `My favorite ads`,
				settings: `Settings`,
				logout: `Logout`,

				fresh_recommendations: `Fresh recommendations`,
				offer_submitted_success: `Your offer is submitted successfully!`,
				provide_valid_price: `Please provide a valid price`,

				public_view: `Public view`,
				copy_public_url: `Copy public URL`,

				no_ads_found: `No ads found.`,
				no_offers_found: `No offers found.`,

				selling: `Selling`,
				buying: `Buying`,

				search_location: `Search city, area or location`,
				search_location_hint: `Your "Current Location" will used by default. Allow it from browser settings.`,
				search_keyword: `Cars, Mobiles and a lot more...`,
				search_keyword_hint: `Search anything you are looking for.`,
				my_location: `My Location`,
				nationwide: `Nationwide`,
				go_on_vacations: `De-activate all ads`,
				delete_all_ads: `Delete all ads`,

				profile_updated_success: `Profile updated successfully!`,
				location_updated_success: `Location updated successfully!`,
				profile_pic_updated_success: `Profile picture updated successfully!`,
				upload_new_profile: `Upload new profile picture`,
				update_current_location: `Update your current location`,
				update_location: `Update Location`,
				update_profile: `Update Profile`,
				full_name: `Full Name`,
				phone: `Phone`,
				bio: `Bio`,
				bio_hint: `Write something about yourself`,
				address: `Address`,
				start_selling: `START SELLING`,
				keyword: `Keyword`,
				search_results: `Search results`,

				change_password: `Change Password`,

				password_required: `Please provide password.`,
				password_doesnt_match: `New password does not match.`,
				password_updated_success: `Password updated successfully! User new password to login next time.`,
				update_password: `Update password`,
				notifications: `Notifications`,
				current_password: `Current password`,
				new_password: `New password`,
				repeat_password: `Repeat new password`,

				report_this_user: `Report this user`,
				report_user_hint: `Report this user as inappropriate`,
				report_user_success: `User is reported successfully! Admin will review your report and update the user status accordingly. Thank you.`,
				report_user: `Report user`,

				remove_favorite: `Remove favorite`,
				favorite_count: `No of times your ad is made favorite.`,
				view_count: `No of times your ad is clicked or viewed.`,

				tos: `Terms of Service`,
				agree_with_tos: `By signing up I agree with Terms of Service.`,
				AboutUS: `About us`,
				barcode: "Barcode",
				similar_ads: "Similar Ads",
				select_city: "Select city",
				select_nationwide: "Select nationwide",
				phone_number_shown: "Yes! Phone number will be visible",
				phone_number_not_shown: "No! Phone number will not be visible",
				location_not_found: "Location not provided",
				extra_fields: "Extra fields",
				attention: "Attention"
			}
		},
		ar: {
			translations: {
				login: "تسجيل الدخول",
				login_here: "تسجيل الدخول هنا",
				signup: "التسجيل",
				signup_here: "التسجيل هنا",
				email: "البريد الالكتروني",
				password: "كلمة السر",
				reset_here: "اعادة الضبط هنا",
				reset_your_password: "استرجاع كلمة السر الخاصة بك",
				forgot_password: "نسيت كلمة السر؟",
				full_name: "الاسم الكامل",
				already_have_account: "لديك حساب مسبق؟",
				send_reset_link: "أرسل رابط إعادة التفعيل",
				want_to_login: "تريد تسجيل الدخول؟",
				remember_me: "تذكرني",
				email_address_required: `البريد الالكتروني مطوب.`,
				email_address_valid: `يرجى ادخال بريد الكتروني "ايميل" حقيقي`,
				password_required: `كلمة السر مطلوبة`,
				login_success_redirecting: `تسجيل الدخول صحيح! جاري التحويل ...`,
				name_required: `الاسم مطلوب.`,
				signup_success_check_email: `التسجيل تم بنجاح. يرجى التاكد من بريدك الالكتروني لتفعيل الحساب`,
				password_reset_success_check_email: `يرجى التحقق من بريدك الإلكتروني لتغيير كلمة المرور الخاصة بك`,
				invalid_token: `رمز التنشيط غير صحيح. يرجى عدم تغير رابط التعفيل`,
				account_activation_success_heading: `تم تفعيل الحساب بنجاح`,
				account_activation_success_message: `تم تفعيل حسابك بنجاح. يرجى تسجيل الدخول و استعمال الخدمة`,
				could_not_detect_location: `تعذر الكشف عن موقعك!`,
				provide_all_fields: `يرجى اكمال الحقول المطلوبة`,

				ad_updated: `تم تحديث البيانات بنجاح`,
				ad_created: `تمت اضافة و نشر اعلانك بنجاح`,
				create_ad: `اضافة اعلان`,
				update_ad: `تحديث اعلان`,
				save_changes: `حفظ التغيرات`,
				save_and_publish: `حفظ و نشر الاعلان`,
				go_back: `العودة`,
				details: `معلومات`,
				ad_details: `معلومات الاعلان`,
				category: `التصنيف`,
				sub_category: `التصنيف الفرعي`,
				ad_title: `عنوان الاعلان`,
				ad_title_hint: `اذكر الميزات الرئيسية للمنتج الخاص بك (مثل العلامة التجارية والطراز والعمر والنوع)`,
				condition: `الحالة`,
				description: `الوصف`,
				description_hint: `اضف الحالة , المواصفات و سبب البيع`,
				photo_gallery: `استوديو الصور`,
				phone_number: `رقم الهاتف`,
				select_photos: `حدد صور`,
				select_photos_hint: `حدد صور`,
				price: `رقم`,
				price_hint: `رقم`,
				show_phone_number_on_ad: `اظهر رقم هاتفي في هذا الاعلان`,
				confirm_location: `تأكيد موقعك`,
				confirm_location_hint: `انقر فوق الزر للوصول إلى موقعك الحالي. اسمح للمتصفح بالوصول إلى موقعك للحصول على تجربة بيع أفضل.`,
				access_location: `الوصول الى موقعي`,
				city: `المدينة`,
				state: `الولاية`,
				country: `البلد`,
				sure_to_remove_favorite: `هل انت متأكد من ازالته من المفضلة؟`,
				already_favorite_click_to_remove: `في المفضلة. انقر لإزالته من المفضلة`,
				save_favorite: `اضغط لحفظه في قائمة المفضلات`,
				ad_report_success: `تم الإبلاغ عن الإعلان بنجاح! سيراجع المسؤول تقريرك ويحدّث حالة الإعلان وفقًا لذلك. شكرا جزيلا`,
				report_ad: `الأبلاغ عن اعلان`,
				select_type: `حدد النوع`,
				select_category: `تحديد الصنف`,
				select_sub_category: `تحديد الصنف الفرعي`,
				select_condition: `تحديد الحالة`,
				report_message: `رسالة الى المدير`,

				submit: `أرسل`,
				close: `إغلاق`,
				edit: `تحرير`,
				delete: `حذف`,
				deactivate: `تعطيل`,
				activate: `تفعيل`,
				make_available: `جاهز للبيع`,
				make_sold: `تم البيع`,
				search: `بحث`,
				clear: `Clear`,

				cannot_chat_yourself: `لا تستطيع الدردشة مع نفسك`,
				cannot_offer_yourself: `لا تستطيع ارسال عرض لنفسك`,

				copy_public_url_to_share: `نسخ الرابط للنشر`,
				seller_information: `معلومات البائع`,
				view_seller_profile: `اضغط لعرض الملف الشخصي للبائع`,
				seller_shared_phone: `البائع نشر رقم هاتفه`,
				view_profile: `عرض الملف الشخصي`,
				chat_with_seller: `التحدث مع البائع`,
				make_offer: `تقديم عرض`,
				posted_in: `موقع الاعلان`,
				report_this_ad: `بلغ عن هذا الاعلان`,

				filter_conversations: `تصنيف المحادثة...`,
				type_message: `اكتب رسالتك هنا ...`,
				send: `أرسال`,

				offers: `العروض`,
				messages: `الرسائل`,
				my_profile: `ملفي الشخصي`,
				my_public_profile: `ملفي الشخصي العام`,
				my_ads: `اعلاناتي`,
				my_favorite_ads: `اعلاناتي المفضلة`,
				settings: `الضبط`,
				logout: `الخروج`,

				fresh_recommendations: `توصيات جديدة`,
				offer_submitted_success: `تم تقديم عرضك بنجاح!`,
				provide_valid_price: `يرجى اضافة سعر صحيح`,

				public_view: `المنظر العام`,
				copy_public_url: `نسخ الرابط العام`,

				no_ads_found: `لا توجد اعلانات.`,
				no_offers_found: `لا توجد عروض.`,

				selling: `الضبط`,
				buying: `الشراء`,

				search_location: `البحث في المدينة , المنطقة , او الموقع`,
				search_location_hint: `سيتم استخدام "موقعك الحالي" بشكل افتراضي. اسمح به من إعدادات المتصفح.`,
				search_keyword: `إبحث عن كلمة`,
				search_keyword_hint: `ابحث عن اي شيء.`,
				my_location: `منطقتي`,
				nationwide: `كل البلد`,
				go_on_vacations: `تعطيل كل الاعلانات`,
				delete_all_ads: `حذف كل الاعلانات`,

				profile_updated_success: `تم تحديث الملف الشخصي بنجاح`,
				location_updated_success: `تم تحديث الموقع بنجاح`,
				profile_pic_updated_success: `تم تحديث صورة الملف الشخصي بنجاح`,
				upload_new_profile: `اضف صورة ملف شخصي جديدة`,
				update_current_location: `تحديث عنوانك الحالي`,
				update_location: `تحديث العنوان`,
				update_profile: `تحديث الملف الشخصي`,
				full_name: `الاسم الكامل`,
				phone: `رقم الهاتف`,
				bio: `السيرة الذاتية`,
				bio_hint: `اكتب عن نفسك`,
				address: `العنوان`,
				start_selling: `ابدأ البيع`,
				keyword: `كلمة`,
				search_results: `نتائج البحث`,

				change_password: `تغيير كلمة المرور`,

				password_required: `الرجاء ادخال كلمة السر`,
				password_doesnt_match: `كلمة السر الجديدة غير متطابقة`,
				password_updated_success: `تم تحديث كلمة السر بنجاح! استخدم كلمة مرور جديدة لتسجيل الدخول في المرة القادمة.`,
				update_password: `تحديث كلمة السر`,
				notifications: `الاشعارات`,
				current_password: `كلمة السر الحالية`,
				new_password: `كلمة سر جديدة`,
				repeat_password: `أعد كلمة السر`,

				report_this_user: `بلغ عن هذا المشترك`,
				report_user_hint: `الإبلاغ عن هذا المستخدم كمستخدم غير لائق`,
				report_user_success: `تم الإبلاغ عن المستخدم بنجاح! سيقوم المسؤول بمراجعة تقريرك وتحديث حالة المستخدم وفقًا لذلك. شكرا جزيلا.`,
				report_user: `الابلاغ عن مشترك`,

				remove_favorite: `حذف المفضلة`,
				favorite_count: `No of times your ad is made favorite.`,

				tos: `شروط الاستخدام`,
				agree_with_tos: `الموافقة على شروط الاستخدام`,
				AboutUS: `عن منصة الشورجة`,
				barcode: "رقم الباركود",
				similar_ads: "اعلانات مشابهة",
				select_city: "حدد المدينة",
				select_nationwide: "كل المدن",
				phone_number_shown: "نعم , رقم الهاتف سوف يكون ظاهر",
				phone_number_not_shown: "كلا , رقم الهاتف سوف يكون مخفي",
				location_not_found: "الموقع غير متوفر",
				extra_fields: "حقول اضافية",
				attention: "انتباه"
			}
		}
	},
	lng: "ar", // Set Arabic as the default language
	fallbackLng: "ar",
	debug: false,
	ns: ["translations"],
	defaultNS: "translations",
	interpolation: { escapeValue: false },
    react: { useSuspense: true },
	keySeparator: false,
	interpolation: {
		escapeValue: false,
		formatSeparator: ","
	},
	react: {
		wait: true,
		useSuspense: true
	}
});

export default i18n;
